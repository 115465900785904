import { type Ref } from "vue";
import { useQuery, useMutation, useQueryClient } from "@tanstack/vue-query";
import { meetingService } from "@/core/services/meeting.service";
import { useMeetingId, useStagiaireId } from "./routes";
import type {
  PatchedOwnMeetingUpdateParticipant,
  PatchedOpinionParticipant,
  PatchedMeetingParticipant,
} from "@sgdf/client";
import { internFilter } from "~/core/utils/participant-filter";

export const getParticipantsKey = (meetingId: Ref<string | null>) => {
  return computed(() => ["meetings", meetingId.value, "participants"] as const);
};

export const getOpinionParticipantsKey = (meetingId: Ref<string | null>) => {
  return computed(() => ["meetings", meetingId.value, "opinion-participants"] as const);
}


export const useParticipants = () => {
  const meetingId = useMeetingId();

  const hasPermission = usePermission();

  const enabled = computed(() => {
    return (
      !!meetingId.value && hasPermission.value("view_meetingparticipant", meetingId.value!)
    );
  })

  return useQuery({
    queryKey: getParticipantsKey(meetingId),
    queryFn: () =>
      meetingService
        .getInstance()
        .getParticipantsApi()
        .meetingsParticipantsList({
          meetingUuid: meetingId.value!,
          uuid: meetingId.value!, // API is buggy we should not need this
        }),
    enabled,
    staleTime: Infinity,
  });
};

export const useInterns = () => {
  const meetingId = useMeetingId();

  const hasPermission = usePermission();

  const enabled = computed(() => {
    return (
      !!meetingId.value && hasPermission.value("view_meetingparticipant", meetingId.value!)
    );
  })

  return useQuery({
    queryKey: computed(() => [...(getParticipantsKey(meetingId).value), "interns"]),
    queryFn: () =>
      meetingService
        .getInstance()
        .getParticipantsApi()
        .meetingsParticipantsList({
          meetingUuid: meetingId.value!,
          uuid: meetingId.value!, // API is buggy we should not need this
        }),
    enabled,
    staleTime: Infinity,
    select: (data) => data.results.filter(internFilter),
  });
};


export const useOpinionParticipants = () => {
  const meetingId = useMeetingId();
  const hasPermission = usePermission();

  const enabled = computed(() => {
    return (
      !!meetingId.value && hasPermission.value("view_meetingparticipant", meetingId.value!)
    );
  });

  return useQuery({
    queryKey: getOpinionParticipantsKey(meetingId),
    queryFn: () =>
      meetingService
        .getInstance()
        .getParticipantsApi()
        .meetingsParticipantsOpinionListList({
          meetingUuid: meetingId.value!,
          uuid: meetingId.value!,
        }),
    enabled,
    select: (data) => data.results.filter(internFilter),
  });
};
export const useParticipant = (participantId: ComputedRef<string | null>) => {
  const meetingId = useMeetingId();
  const hasPermission = usePermission();

  const enabled = computed(() => {
    return (
      !!meetingId.value && hasPermission.value("view_meetingparticipant", meetingId.value!) && !!participantId.value
    );
  });

  return useQuery({
    queryKey: computed(() => [...getParticipantsKey(meetingId).value, participantId.value]),
    queryFn: () =>
      meetingService
        .getInstance()
        .getParticipantsApi()
        .meetingsParticipantsRetrieve({
          meetingUuid: meetingId.value!,
          uuid: participantId.value!,
        }),
    enabled,
  });
};

export const useOpinionParticipant = (
  participantId: ComputedRef<string | null>,
) => {
  const meetingId = useMeetingId();
  const hasPermission = usePermission();

  const enabled = computed(() => {
    return (
      !!meetingId.value && hasPermission.value("view_meetingparticipant", meetingId.value!) && !!participantId.value
    );
  });

  return useQuery({
    queryKey: computed(() => [...getOpinionParticipantsKey(meetingId).value, participantId.value]),
    queryFn: () =>
      meetingService
        .getInstance()
        .getParticipantsApi()
        .meetingsParticipantsOpinionRetrieve({
          meetingUuid: meetingId.value!,
          uuid: participantId.value!,
        }),
    enabled,
  });
};

export const useAvatarUpload = (participantId: Ref<string>) => {
  const meetingId = useMeetingId();

  const refreshUploadUrl = async () => {
    if (participantId.value) {
      const resp = await meetingService
        .getInstance()
        .getParticipantsApi()
        .meetingsParticipantsRetrieve({
          meetingUuid: meetingId.value!,
          uuid: participantId.value,
        });
      return resp.avatar_url;
    }
    return null;
  };

  return refreshUploadUrl;
};

export const useObservationCountByParticipant = () => {
  const meetingId = useMeetingId();
  const hasPermission = usePermission();

  const enabled = computed(() => {
    const value = (
      !!meetingId.value && hasPermission.value("view_meetingcomment", meetingId.value!)
    )
    return value;
  })

  return useQuery({
    queryKey: computed(() => [...getParticipantsKey(meetingId).value, "observation-count"]),
    queryFn: () =>
      meetingService
        .getInstance()
        .getParticipantsApi()
        .meetingsParticipantsList({
          meetingUuid: meetingId.value!,
          uuid: meetingId.value!,
        }),
    enabled,
    select: (data) => {
      const interns = data.results?.filter(
        (participant) => participant.role.intranet_id === 0,
      );

      return interns?.reduce(
        (acc, p) => ({ ...acc, [p.id]: p.observation_count }),
        {},
      );
    },
  });
};

export const useCurrentStagiaire = () => {
  const meetingId = useMeetingId();
  const stagiaireId = useStagiaireId();

  return useQuery({
    queryKey: computed(() => ["meetings", meetingId.value, "participants", stagiaireId.value]),
    queryFn: () =>
      meetingService
        .getInstance()
        .getParticipantsApi()
        .meetingsParticipantsRetrieve({
          meetingUuid: meetingId.value!,
          uuid: stagiaireId.value!,
        }),
  });
};

export const useSearchParticipants = (filter: Ref<string>) => {
  const meetingId = useMeetingId();
  return useQuery({
    queryKey: computed(() => [...getParticipantsKey(meetingId).value, "search", filter.value]),
    queryFn: () =>
      meetingService
        .getInstance()
        .getParticipantsApi()
        .meetingsParticipantsList({
          meetingUuid: meetingId.value!,
          uuid: meetingId.value!,
          search: filter.value,
          ordering: "first_name",
          pageSize: 10,
        }),
    enabled: computed(() => Boolean(filter) && Boolean(meetingId.value)),
  });
};

export const useUpdateOpinionParticipantMutation = ({
  onSuccess,
}: { onSuccess?: () => void } = {
  }) => {
  const meetingId = useMeetingId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      participant: Omit<
        PatchedOpinionParticipant,
        "id" | "meeting_id" | "role" | "admin_first_name" | "admin_last_name"
      > & { id: string },
    ) =>
      meetingService
        .getInstance()
        .getParticipantsApi()
        .meetingsParticipantsOpinionPartialUpdate({
          meetingUuid: meetingId.value!,
          uuid: participant.id,
          patchedOpinionParticipant: participant,
        }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: getParticipantsKey(meetingId),
      });
      queryClient.invalidateQueries({
        predicate: (query) => {
          const [type, id, entity, entityId] = query.queryKey;
          return (
            type === "meetings" &&
            id === meetingId.value &&
            entity === "opinion-participants" &&
            !entityId || entityId === data.id
          );
        },
      });
      onSuccess?.();
    },
  });
};
export const useUpdateParticipantMutation = () => {
  const meetingId = useMeetingId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      participant: Omit<
        PatchedMeetingParticipant,
        | "id"
        | "user_id"
        | "created"
        | "updated"
        | "meeting"
        | "user"
        | "role"
        | "daily_task_team"
        | "teams"
        | "fonction"
        | "structure_principale"
        | "track"
        | "canceled"
        | "observation_count"
        | "last_sync"
        | "avis_prevalidation"
        | "avis_validation"
        | "avis_validation_gestionnaire"
        | "signature_url"
        | "signature_available"
        | "avatar_url"
        | "avatar_available"
        | "user_avatar_url"
        | "user_avatar_available"
      > & { id: string },
    ) =>
      meetingService
        .getInstance()
        .getParticipantsApi()
        .meetingsParticipantsPartialUpdate({
          meetingUuid: meetingId.value!,
          uuid: participant.id,
          patchedMeetingParticipant: participant,
        }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          const [type, id, entity, entityId] = query.queryKey;
          return (
            type === "meetings" &&
            id === meetingId.value &&
            entity === "opinion-participants" &&
            !entityId || entityId === data.id
          );
        },
      });
      queryClient.invalidateQueries({
        predicate: (query) => {
          const [type, id, entity, entityId] = query.queryKey;
          return (
            type === "meetings" &&
            id === meetingId.value &&
            entity === "participants" &&
            !entityId || entityId === data.id
          );
        },
      });
    },
  });
};

export const useUpdateMyParticipantMutation = () => {
  const meetingId = useMeetingId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (
      participant: PatchedOwnMeetingUpdateParticipant & { id: string },
    ) =>
      meetingService
        .getInstance()
        .getMeetingsApi()
        .meetingsMyParticipationPartialUpdate({
          uuid: meetingId.value!,
          patchedOwnMeetingUpdateParticipant: participant,
        }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          const [type, id, entity] = query.queryKey;
          return (
            type === "meetings" &&
            id === meetingId.value &&
            entity === "participants"
          );
        },
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === "me",
      });
    },
  });
};

export const useDeleteSignatureMutation = () => {
  const meetingId = useMeetingId();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (participant: { id: string }) =>
      meetingService
        .getInstance()
        .getParticipantsApi()
        .meetingsParticipantsResetSignatureDestroy({
          meetingUuid: meetingId.value!,
          uuid: participant.id,
        }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getParticipantsKey(meetingId),
      });
      queryClient.invalidateQueries({
        queryKey: getOpinionParticipantsKey(meetingId),
      });
    },
  });
};
